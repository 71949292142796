import React from "react";


interface Props {
  title: string | JSX.Element;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  capitalizeTitle?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  className?: string;
  altClassName?: string;
  isBusy?: boolean;
}

const Button: React.FC<Props> = ({
  title,
  onClick,
  capitalizeTitle,
  disabled,
  altClassName,
  isBusy,
}) => {
  return (
    <div className={disabled? "opacity-75 " : ""}>
      <button className={altClassName || `py-3 lg:text-xl w-full fw-600  ${disabled? 'cursor-not-allowed btn-disabled' : 'btn-primary'}`} onClick={onClick ? onClick : undefined} disabled={disabled}>
      {isBusy ? (
          "loading"
        ) : capitalizeTitle ? (
          title
        ) : (
          title
        )}
      </button>
    </div>
  );
};

export default Button;